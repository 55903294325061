import {onMounted, ref} from 'vue'
import LocationService from "@/service/LocationService";

export function useLocationDropdown(mounted = true) {
    const locationService = new LocationService();
    const provinces = ref({});
    const cities = ref({});
    const districts = ref({});

    const selectedProvince = ref(null);
    const selectedCity = ref(null);
    const selectedDistrict = ref(null);

    onMounted(async () => {
        if (mounted) await getProvinceDropdown()
    })

    const onProvinceChange = async (provinceId) => {
        selectedCity.value = null
        selectedDistrict.value = null
        cities.value = {}
        districts.value = {}

        if (provinceId) {
            await getCityDropdown(provinceId)
        }
    }

    const onCityChange = async (cityId) => {
        selectedDistrict.value = null
        districts.value = {}

        if (cityId) {
            await getDistrictDropdown(cityId)
        }
    }

    const getProvinceDropdown = async () => {
        const {data} = await locationService.getProvinces()
        const listProvinces = {}

        data?.data.provinces.forEach((province) => {
            listProvinces[province.id] = province.name
        })

        provinces.value = listProvinces
    }

    const getCityDropdown = async (provinceId) => {
        const listCities = {}
        const {data} = await locationService.getCities(provinceId)
        data?.data.cities.forEach((city) => {
            listCities[city.id] = city.name
        })
        cities.value = listCities
    }

    const getDistrictDropdown = async (cityId) => {
        const listDistricts = {}
        const {data} = await locationService.getDistricts(cityId)
        data?.data.districts.forEach((district) => {
            listDistricts[district.id] = district.name
        })
        districts.value = listDistricts
    }

    return {
        provinces,
        cities,
        districts,
        selectedProvince,
        selectedCity,
        selectedDistrict,
        onProvinceChange,
        onCityChange,
        getProvinceDropdown,
        getCityDropdown,
        getDistrictDropdown
    }
}

export function useGeoLocation() {
    const center = ref({lat: -6.2088, lng: 106.8456})
    const zoom = ref(10)
    const markers = ref([
        {
            position: {
                lat: -6.2088, lng: 106.8456
            }
        }
    ])
    const lat = ref(null)
    const lng = ref(null)

    const onMarkerChange = (data) => {
        const {latLng} = data
        markers.value = [
            {
                position: {
                    lat: latLng.lat(), lng: latLng.lng()
                }
            }
        ]
        lat.value = latLng.lat()
        lng.value = latLng.lng()
    }

    const initLatLng = (newLat, newLng) => {
        lat.value = newLat
        lng.value = newLng
        markers.value = [
            {
                position: {
                    lat: newLat, lng: newLng
                }
            }
        ]
    }

    const updateCenter = (position) => {
        center.value = position
    }

    return {center, zoom, markers, lat, lng, onMarkerChange, updateCenter, initLatLng}
}