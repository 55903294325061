<template>
  <div class="tw-overflow-hidden">
    <div>
      <h1
        for="position"
        class="tw-text-3xl sm:tw-text-4xl tw-font-bold tw-text-center"
      >
        Lokasi
      </h1>
      <div
        class="formkit-help md:text-base lg:tw-text-lg tw-text-gray-400 tw-text-center tw-max-w-prose tw-mx-auto"
      >
        Geser pin merah atau klik sesuai lokasi salon Anda untuk menempatkan
        alamat yang benar
      </div>
      <div class="tw-overflow-hidden">
        <GMapMap
          :center="center"
          :zoom="zoom"
          :maxZoom="18"
          map-type-id="terrain"
          class="tw-mt-4 tw-h-72 sm:tw-h-96 tw-w-full tw-relative"
          @click="changeMarker"
          :options="{
            mapTypeControl: false,
            streetViewControl: false,
          }"
        >
          <GMapAutocomplete
            ref="autoCompleteRef"
            placeholder="Search..."
            @place_changed="setPlace"
            class="px-3 py-2 tw-absolute tw-top-2 tw-left-2 tw-rounded-md tw-shadow"
          >
          </GMapAutocomplete>
          <GMapCluster>
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="updateCenter(m.position)"
            />
          </GMapCluster>
        </GMapMap>
      </div>
    </div>
    <div class="">
      <FormKit
        name="address"
        type="text"
        v-model="address"
        label="Alamat Lengkap"
        placeholder="Masukkan alamat salon"
        validation="required"
        validation-visibility="dirty"
        :classes="{
          ...defaultFormikClass,
          outer: `${defaultFormikClass.outer} tw-mb-0`,
        }"
      />
      <small class="tw-text-gray-400"
        >Apabila lokasi di map kurang akurat, Anda dapat menulis detail alamat
        disini</small
      >
      <FormKit
        name="address_caption"
        type="text"
        v-model="address_caption"
        label="Keterangan Alamat"
        placeholder="Contoh: Gedung Lantai 3"
        validation="required"
        validation-visibility="dirty"
        :classes="{
          ...defaultFormikClass,
          outer: `${defaultFormikClass.outer} tw-mb-0`,
        }"
      />
      <div class="tw-flex tw-items-center tw-gap-2">
        <FormKit
          name="province"
          label="Provinsi"
          placeholder="Provinsi"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          disabled
          v-model="province"
        />
        <FormKit
          name="city"
          label="Kota/Kabubaten"
          placeholder="Kota"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          disabled
          v-model="city"
        />
      </div>
      <div class="tw-flex tw-items-center tw-gap-2">
        <FormKit
          name="district"
          label="Kecamatan"
          placeholder="Kecamatan"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          disabled
          v-model="district"
        />
        <FormKit
          name="post_code"
          label="Kode Pos"
          placeholder="Kode Pos"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikClass"
          v-model="post_code"
        />
      </div>
    </div>
  </div>
  <div class="tw-gap-3 tw-flex tw-items-center tw-justify-between">
    <Button
      label="Sebelumnya"
      icon="pi pi-arrow-left"
      outlined
      class="px-6 tw-mt-5 tw-bg-white tw-text-blue-600 hover:tw-bg-transparent"
      @click="$emit('prev-page', { pageIndex: 1 })"
    />
    <Button
      label="Selanjutnya"
      icon="pi pi-arrow-right"
      iconPos="right"
      :disabled="!address || !province || !city || !district || !post_code"
      class="px-6 p-button-info tw-mt-5"
      @click="
        () => {
          const province_id = Object.keys(provinces).find(
            (val) =>
              provinces[val]
                .replace('DKI ', '')
                .replace('DI ', '')
                .toLowerCase() ===
              province
                .toLowerCase()
                .replace('daerah khusus ibukota ', '')
                .replace('daerah istimewa ', '')
          );
          const city_id = Object.keys(cities).find(
            (val) =>
              cities[val]
                .toLowerCase()
                .replace('kota ', '')
                .replace('kabupaten ', '') ===
              city.toLowerCase().replace('kota ', '').replace('kabupaten ', '')
          );
          const district_id = Object.keys(districts).find(
            (val) =>
              districts[val].toLowerCase().replace('kecamatan ', '') ===
              district.toLowerCase().replace('kecamatan ', '')
          );

          if (!province_id || !city_id || !district_id) return;
          $emit('next-page', {
            pageIndex: 1,
            formData: {
              lat,
              long: lng,
              address,
              province_id,
              city_id,
              district_id,
              postal_code: post_code,
            },
          });
        }
      "
    />
  </div>
</template>
<script setup>
import { useGeoLocation, useLocationDropdown } from "@/composables/location";
import { onMounted, ref } from "vue";
import { defaultFormikClass } from "@/constants/formik";

const {
  center,
  zoom,
  markers,
  lat,
  lng,
  onMarkerChange,
  updateCenter,
  initLatLng,
} = useGeoLocation();

const {
  provinces,
  cities,
  districts,
  getProvinceDropdown,
  getCityDropdown,
  getDistrictDropdown,
} = useLocationDropdown();

const province = ref("");
const city = ref("");
const district = ref("");
const post_code = ref("");
const autoCompleteRef = ref(null);
const address = ref("");
const address_caption = ref("");

async function getAddress(latitude, longitude) {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBgbDBAgirnm3vR-kLCkkRne2cUY0s3qQU`,
    {
      headers: {
        "Accept-Language": "id-ID,id;q=0.9,en-US;q=0.8,en;q=0.7",
      },
    }
  );

  const data = await res.json();

  return data;
}

const setPlace = (event) => {
  province.value = event.address_components.find((val) =>
    val.types.includes("administrative_area_level_1")
  ).long_name;
  city.value = event.address_components.find((val) =>
    val.types.includes("administrative_area_level_2")
  ).long_name;
  district.value = event.address_components
    .find((val) => val.types.includes("administrative_area_level_3"))
    .long_name.replace("Kecamatan ", "");
  post_code.value = event.address_components.find((val) =>
    val.types.includes("postal_code")
  ).long_name;

  address.value = event.formatted_address;
  initLatLng(event.geometry.location.lat(), event.geometry.location.lng());
  updateCenter(event.geometry.location);
  zoom.value = 15;
};

const changeMarker = (data) => {
  getAddress(data.latLng.lat(), data.latLng.lng()).then((res) => {
    const data =
      res.results.find((val) => val.types.includes("street_address")) ??
      res.results.find((val) => val.types.includes("route")) ??
      res.results[0];

    address.value = data.formatted_address;
    province.value = data.address_components.find((val) =>
      val.types.includes("administrative_area_level_1")
    ).long_name;
    city.value = data.address_components.find((val) =>
      val.types.includes("administrative_area_level_2")
    ).long_name;
    district.value = data.address_components
      .find((val) => val.types.includes("administrative_area_level_3"))
      .long_name.replace("Kecamatan ", "");
    post_code.value = data.address_components.find((val) =>
      val.types.includes("postal_code")
    ).long_name;

    const city_id = Object.keys(cities.value).find((val) => {
      return (
        cities.value[val]
          .toLowerCase()
          .replace("kota ", "")
          .replace("kabupaten ", "") === city.value.toLowerCase()
      );
    });

    console.info(city_id);
  });
  onMarkerChange(data);
};

onMounted(() => {
  getProvinceDropdown();
  getCityDropdown();
  getDistrictDropdown();
});
</script>
