import axios from "axios";

const LOCATION_URL = 'api/v1/locations'

export default class LocationService {
    getProvinces() {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${LOCATION_URL}/ddl/provinces`)
    }

    getCities(provinceId) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${LOCATION_URL}/ddl/cities`, {
            params: {province_id: provinceId}
        });
    }

    getDistricts(cityId) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${LOCATION_URL}/ddl/districts`, {
            params: {city_id: cityId}
        });
    }
}
